import { FC, useCallback } from 'react';

import { TrackLinkCardClicked } from '../../utils/analytics';
import { ButtonCard } from '../FramingGuide/ContactPanel/ButtonCard';
import { MediaCard } from '../FramingGuide/ContactPanel/MediaCard';
import { useHubspotChat } from '../../hooks/useHubspotChat';
import classNames from 'classnames';

interface Props {
  links?: LinkCardGroupComponent[];
}

export const LinkCards: FC<Props> = ({ links }) => {
  const { openHandler } = useHubspotChat();
  const group = links?.[0];

  const handleClick = useCallback(
    (title: string, url: string) => {
      TrackLinkCardClicked({
        placement: group?.analyticsPlacement,
        url,
        title,
      });
    },
    [group],
  );

  if (!group?.cards || group?.cards.length === 0) {
    return null;
  }

  return (
    <div className="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      {group.cards.map((card, index) => (
        <div
          key={card._uid}
          className={classNames(
            index === 0 ? 'md:col-span-2 lg:col-span-2' : '',
          )}
        >
          {card.component === 'linkCardMedia' && (
            <MediaCard
              href={card.link.cached_url}
              image={card.image.filename}
              onClick={() => handleClick(card.headline, card.link.cached_url)}
              title={card.headline}
              variant={index === 0 ? '3/2' : '3/4'}
            />
          )}
          {card.component === 'linkCardCTA' && (
            <ButtonCard
              onClick={() => {
                handleClick(card.headline, card.ctaLink?.url || '');
                openHandler();
              }}
              text={card.ctaText || ''}
              title={card.headline}
              variant="3/4"
            />
          )}
        </div>
      ))}
    </div>
  );
};
