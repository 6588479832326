import { Button } from '@design-system';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { useHubspotChat } from '../../hooks/useHubspotChat';
import {
  TrackLinkCardCtaClicked,
  TrackLinkCardSalesCtaClicked,
} from '../../utils/analytics';
import { renderStoryblokRichText } from '../../utils/renderStoryblokRichText';

export interface LinkCardCtaProps {
  headline: string;
  subHeadline?: StoryblokTextAreaType;
  ctaLink?: string;
  ctaText?: string;
}

const LinkCardCta: React.FC<LinkCardCtaProps> = (props) => {
  const { headline, subHeadline, ctaLink, ctaText } = props;

  const { openHandler } = useHubspotChat();
  const { t } = useTranslation('linkCards');
  const preparedCtaText =
    ctaText || t('cta.ctaTextDefault') || 'Contact support';

  return (
    <div className="link-card items-end justify-between md:flex-col md:justify-end absolute inset-0 cta bg-neutral-2 rounded-md p-4 sm:p-6">
      <div
        className={classNames(
          'flex flex-col-reverse md:row-start-1 md:row-span-2',
        )}
      >
        <div className="font-bold text-md md:text-lg">{headline}</div>
        {subHeadline && (
          <div className="text-neutral-4 text-md md:text-lg mb-3 md:mb-4 font-bold">
            {renderStoryblokRichText(subHeadline)}
          </div>
        )}
      </div>
      <div className="row-start-2 md:col-start-1 md:row-start-3">
        {ctaLink && (
          <a className="inline-block min-w-max" href={ctaLink}>
            <Button
              label={preparedCtaText}
              variant="primary"
              size="md"
              onClick={() => TrackLinkCardCtaClicked({ cta: headline })}
            />
          </a>
        )}
        {!ctaLink && (
          <div className="inline-block min-w-max">
            <Button
              label={preparedCtaText}
              variant="primary"
              size="md"
              onClick={() => {
                openHandler();
                TrackLinkCardSalesCtaClicked({ cta: headline });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkCardCta;
