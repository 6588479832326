import classNames from 'classnames';
import React from 'react';
import { ImageSizes } from '../../helpers/images';
import { LinkCardPlacement } from '../../types/Analytics';
import LinkCardCta from './LinkCardCta';
import LinkCardMedia from './LinkCardMedia';
import LinkCardText from './LinkCardText';

const IMAGE_SIZES_FIRST: ImageSizes = {
  sm: { ratio: '4/3', span: 12 },
  md: { ratio: '3/2', span: 12 },
  lg: { ratio: '3/2', span: 6 },
};

const IMAGE_SIZES_OTHER: ImageSizes = {
  sm: { ratio: '4/3', span: 12 },
  md: { ratio: '3/4', span: 6 },
  lg: { ratio: '3/4', span: 3 },
};

interface LinkCardGroupProps extends React.HTMLProps<HTMLDivElement> {
  cards?: LinkCardComponent[];
  analyticsPlacement: LinkCardPlacement;
}

const LinkCardGroup: React.FC<React.PropsWithChildren<LinkCardGroupProps>> = ({
  analyticsPlacement,
  cards,
  children,
  ...props
}) => (
  <div
    {...props}
    className={classNames(
      'grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-4',
      props.className,
    )}
  >
    {cards?.map((c, idx) => {
      switch (c.component) {
        case 'linkCardCTA': {
          return (
            <LinkCardContainer key={c._uid} index={idx}>
              <LinkCardCta
                headline={c.headline}
                subHeadline={c.subHeadline}
                ctaLink={c.ctaLink?.url}
                ctaText={c.ctaText}
              />
            </LinkCardContainer>
          );
        }

        case 'linkCardText': {
          return (
            <LinkCardContainer key={c._uid} index={idx}>
              <LinkCardText
                headline={c.headline}
                subHeadline={c.subHeadline}
                link={
                  c.link.story?.full_slug
                    ? `/${c.link.story.full_slug}`
                    : c.link.url
                }
                analyticsPlacement={analyticsPlacement}
              />
            </LinkCardContainer>
          );
        }

        case 'linkCardMedia': {
          switch (true) {
            case Boolean(c.video && c.video.vimeo_oembed !== null):
              return (
                <LinkCardContainer key={c._uid} index={idx}>
                  <LinkCardMedia
                    headline={c.headline}
                    subHeadline={c.subHeadline}
                    link={
                      c.link.story?.full_slug
                        ? `/${c.link.story.full_slug}`
                        : c.link.url
                    }
                    media={{
                      type: 'video',
                      asset: c.video,
                    }}
                    imageSizes={
                      idx === 0 ? IMAGE_SIZES_FIRST : IMAGE_SIZES_OTHER
                    }
                    analyticsPlacement={analyticsPlacement}
                  />
                </LinkCardContainer>
              );

            case Boolean(c.image && c.image.filename):
              return (
                <LinkCardContainer key={c._uid} index={idx}>
                  <LinkCardMedia
                    headline={c.headline}
                    subHeadline={c.subHeadline}
                    link={
                      c.link.story?.full_slug
                        ? `/${c.link.story.full_slug}`
                        : c.link.url
                    }
                    media={{
                      type: 'image',
                      url: c.image.filename,
                      alt: c.image.alt,
                      focus: c.image.focus,
                    }}
                    imageSizes={
                      idx === 0 ? IMAGE_SIZES_FIRST : IMAGE_SIZES_OTHER
                    }
                    analyticsPlacement={analyticsPlacement}
                  />
                </LinkCardContainer>
              );

            default:
              console.error(
                'LinkCardMedia has neither a video or image attached',
              );

              return null;
          }
        }

        default:
          return null;
      }
    })}
    {children}
  </div>
);

export default LinkCardGroup;

const LinkCardContainer: React.FC<{
  index: number;
  children?: React.ReactNode;
}> = ({ children, index }) => {
  return (
    <div
      className={classNames('flex-1 relative w-full', {
        'aspect-w-4 aspect-h-3 md:aspect-w-3 md:aspect-h-2 md:col-span-2 lg:col-span-2':
          index === 0,
        'aspect-w-4 aspect-h-3 md:aspect-w-3 md:aspect-h-4': index > 0,
      })}
    >
      {children}
    </div>
  );
};
