import { FC, useMemo } from 'react';

import { TrackAccordion } from '../../../utils/analytics';
import { renderStoryblokRichText } from '../../../utils/renderStoryblokRichText';
import FAQAccordion from '../../Content/FAQAccordion';
import { Image } from '../../Image';

interface Props {
  analytics: Analytics;
  faq?: SupportPageType;
  image?: StoryblokImage;
  title: string;
}

export const SupportFAQ: FC<Props> = ({ analytics, faq, image, title }) => {
  const questions = useMemo(() => {
    return (faq?.content || []).filter((blok) => {
      if (blok.component === 'supportBlock') {
        return (blok as SupportBlock).isAccordion;
      }
      return false;
    }) as SupportBlock[];
  }, [faq?.content]);

  if (questions.length === 0) {
    return null;
  }

  return (
    <section>
      <div className="mb-4">
        <h2 className="text-neutral-4 text-md md:text-lg">{title}</h2>
      </div>
      <div className="grid-section">
        <div className="col-span-12 md:col-span-6 order-2 md:order-1 divide-y divide-divider/20">
          {questions.map((question) => (
            <div key={question._uid}>
              <FAQAccordion
                onClick={(expanded) => {
                  TrackAccordion({
                    ...analytics,
                    title: question.title,
                    isFAQ: true,
                    expanded,
                  });
                }}
                text={renderStoryblokRichText(question.text)}
                title={renderStoryblokRichText(question.title)}
              />
            </div>
          ))}
        </div>
        {image && (
          <div className="col-span-12 md:col-span-6 order-1 md:order-2 mb-4 md:mb-0">
            <div className="rounded-sm overflow-hidden">
              <Image
                alt={image.alt}
                focus={image.focus}
                size={{
                  sm: { ratio: '3/2', span: 12 },
                  md: { ratio: '3/2', span: 6 },
                  lg: { ratio: '3/2', span: 6 },
                }}
                src={image.filename}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
