import Link from 'next/link';
import { FC } from 'react';
import { Image } from '../Image';

interface LinkCardImageProps {
  className?: string;
  card: LinkCardImageComponent;
  onClick: () => void;
}

export const LinkCardImage: FC<LinkCardImageProps> = ({ card, onClick }) => (
  (<Link
    href={card.link.url}
    passHref
    className="block group relative w-full"
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex={0}
    target={card.link.linktype === 'story' ? '_self' : '_blank'}>

    <Image
      alt={card.title}
      hoverEffect
      size={{
        sm: { ratio: '4/3', span: 12 },
        md: { ratio: '4/3', span: 6 },
        lg: { ratio: '4/3', span: 6 },
      }}
      src={card.image.filename}
    />
    <div className="absolute inset-0 bg-dark/40 pointer-events-none">
      <div className="p-4 flex flex-col justify-end h-full">
        <div className="max-w-md md:text-md lg:text-lg text-white w-full">
          <div>{card.subtitle}&nbsp;</div>
          <div className="font-bold">{card.title}</div>
        </div>
      </div>
    </div>

  </Link>)
);
