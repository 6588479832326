import { Icon } from '@design-system';
import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { LinkCardPlacement } from '../../types/Analytics';
import { TrackLinkCardClicked } from '../../utils/analytics';
import { renderStoryblokRichText } from '../../utils/renderStoryblokRichText';

export interface LinkCardTextProps {
  analyticsPlacement?: LinkCardPlacement;
  headline: string;
  subHeadline?: StoryblokTextAreaType;
  link: string;
}

const LinkCardText: React.FC<LinkCardTextProps> = (props) => {
  const { analyticsPlacement, headline, subHeadline, link } = props;
  const hasSubHeadline = Boolean(subHeadline);

  return (
    (<Link
      href={link}
      prefetch={false}
      className={classNames(
        'link-card flex flex-col items-start justify-end group absolute inset-0 rounded-sm text-light',
        { 'bg-dark': hasSubHeadline },
        { 'bg-neutral-1': !hasSubHeadline },
      )}
      onClick={() =>
        TrackLinkCardClicked({
          placement: analyticsPlacement,
          url: link,
          title: headline,
        })
      }>

      <div className="w-full flex-1 flex flex-col items-start justify-end space-y-3 z-40 p-4 sm:p-6">
        {subHeadline && (
          <div className="text-neutral-4 text-md md:text-lg font-bold mb-3 md:mb-4">
            {renderStoryblokRichText(subHeadline)}
          </div>
        )}
        <div className="text-md md:text-lg font-bold text-light z-40">
          {headline}
        </div>
        <div className="bg-white text-neutral-6 rounded-full">
          <Icon name="ic_arrow_top_right" size="md" className="m-3" />
        </div>
      </div>
      <div className="bg-dark/10 group-hover:bg-dark/30 transition-colors duration-300 absolute inset-0 z-30" />

    </Link>)
  );
};

export default LinkCardText;
