import React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@design-system';

type Props = {
  title: string;
  text: string | React.ReactNode;
  disabled?: boolean;
  visible?: boolean;
  onClick?: (visible: boolean) => void;
};

const FAQAccordion: React.FC<Props> = ({
  title,
  text,
  disabled = false,
  visible,
  onClick,
}) => {
  const [visibleDropDown, setVisible] = useState(false);

  useEffect(() => {
    visible !== undefined && setVisible(visible);
    if (disabled && visibleDropDown) setVisible(false);
  }, [disabled, visible]);

  return (
    <div className="flex flex-col w-full bg-white">
      <button
        className={classNames(
          'flex w-full items-center',
          'group peer text-left transition duration-300 focus:outline-none',
          {
            'cursor-not-allowed text-neutral-4': disabled,
          },
        )}
        onClick={() => {
          const visibility = !visibleDropDown;

          setVisible(visibility);
          onClick?.(visibility);
        }}
        disabled={disabled}
      >
        <span className="flex-grow py-4 font-bold transition duration-300 group-hover:text-neutral-4">
          {title}
        </span>
        <div className="flex justify-center items-center p-2 pointer-events-none transition duration-300 group-hover:text-neutral-4">
          {visibleDropDown ? (
            <Icon name="ic_chevron_up" />
          ) : (
            <Icon name="ic_chevron_down" />
          )}
        </div>
      </button>
      <span
        className={classNames(
          'w-full transition duration-300',
          `${visibleDropDown ? 'h-auto' : 'h-0 overflow-hidden'}`,
        )}
      >
        {text}
      </span>
    </div>
  );
};

export default FAQAccordion;
