import { Icon } from '@design-system';
import Link from 'next/link';
import React from 'react';
import { ImageSizes } from '../../helpers/images';
import { LinkCardPlacement } from '../../types/Analytics';
import { TrackLinkCardClicked } from '../../utils/analytics';
import { renderStoryblokRichText } from '../../utils/renderStoryblokRichText';
import BackgroundVideo from '../BackgroundVideo';
import { Image } from '../Image';
import { VimeoPlugin } from '../../types/Vimeo';

export interface MediaImage {
  type: 'image';
  url: string;
  alt: string;
  focus?: string;
}

export interface MediaVideo {
  type: 'video';
  asset: VimeoPlugin;
}

export interface LinkCardMediaProps {
  analyticsPlacement?: LinkCardPlacement;
  headline: string;
  subHeadline?: StoryblokTextAreaType;
  link: string;
  media: MediaImage | MediaVideo;
  imageSizes: ImageSizes;
}

const LinkCardMedia: React.FC<LinkCardMediaProps> = ({
  analyticsPlacement,
  headline,
  subHeadline,
  link,
  media,
  imageSizes,
}) => {
  return (
    (<Link
      href={link}
      prefetch={false}
      className="link-card flex flex-col group absolute inset-0 bg-light media rounded-sm text-light"
      onClick={() =>
        TrackLinkCardClicked({
          placement: analyticsPlacement,
          url: link,
          title: headline,
        })
      }>

      <div className="absolute inset-0">
        {media.type === 'image' && (
          <Image
            alt={media.alt}
            className="h-full w-full"
            hoverEffect
            size={imageSizes}
            src={media.url}
          />
        )}

        {media.type === 'video' && (
          <BackgroundVideo
            className="object-cover h-full w-full"
            vimeo={media.asset}
          />
        )}

        <div className="bg-dark/10 group-hover:bg-dark/30 transition-colors duration-300 absolute inset-0 z-30" />
      </div>
      <div className="w-full flex-1 flex flex-col items-start justify-end space-y-3 z-40 p-4 sm:p-6">
        {subHeadline && (
          <div className="text-neutral-4 text-md md:text-lg font-bold mb-3 md:mb-4">
            {renderStoryblokRichText(subHeadline)}
          </div>
        )}
        <div className="text-md md:text-lg font-bold text-light z-40">
          {headline}
        </div>
        <div className="bg-white text-neutral-6 rounded-full">
          <Icon name="ic_arrow_top_right" size="md" className="m-3" />
        </div>
      </div>

    </Link>)
  );
};

export default LinkCardMedia;
